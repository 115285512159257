import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { DatalistAddIconBtnComponent } from './datalist-add-icon-btn.component';
import { DatalistRemoveIconBtnComponent } from './datalist-remove-icon-btn.component';

@Component({
  selector: 'mri-datalist-footer',
  standalone: true,
  template: `
    <div class="mri-toolbar mri-datalist__footer-toolbar">
      <div class="mri-toolbar__group">
        @if (showAdd) {
          <ng-container [ngTemplateOutlet]="addBtnTemplate || defaultAddBtnTemplate"></ng-container>
        }
        <ng-template #defaultAddBtnTemplate>
          <button [disabled]="!canAdd" mriDatalistAddIconBtn (click)="addItemToList.emit()"></button>
        </ng-template>
        @if (showRemove) {
          <ng-container [ngTemplateOutlet]="removeBtnTemplate || defaultRemoveBtnTemplate"></ng-container>
        }
        <ng-template #defaultRemoveBtnTemplate>
          <button
            [disabled]="!canRemove"
            mriDatalistRemoveIconBtn
            (click)="removeItemFromList.emit(selectedItem)"
          ></button>
        </ng-template>
      </div>
      @if (actionsBtnTemplate && showActions) {
        <div class="mri-toolbar__group">
          <ng-container [ngTemplateOutlet]="actionsBtnTemplate"></ng-container>
        </div>
      }
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `
  ],
  imports: [NgTemplateOutlet, DatalistAddIconBtnComponent, DatalistRemoveIconBtnComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatalistFooterComponent {
  @Input() selectedItem?: unknown;
  @Input() canRemove = true;
  @Input() canHaveActions = true;
  @Input() canAdd = true;
  @Input() showRemove = true;
  @Input() showAdd = true;
  @Input() showActions = true;
  @Output() addItemToList = new EventEmitter();
  @Output() removeItemFromList = new EventEmitter<unknown>();
  @Output() actions = new EventEmitter();

  @Input() actionsBtnTemplate?: TemplateRef<never>;
  addBtnTemplate?: TemplateRef<never>;
  removeBtnTemplate?: TemplateRef<never>;

  @HostBinding('class') readonly hostClass = 'mri-datalist__footer';
  @HostBinding('attr.role') readonly ariaRole = 'contentinfo';
  @HostBinding('attr.aria-label') readonly ariaLabel = 'Data list toolbar';
}
