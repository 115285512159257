@if (showHeader) {
  <header class="mri-datalist__header">
    @if (headerTitle) {
      <label class="mri-datalist__heading">{{ headerTitle }}</label>
    }
    @if (showSearch) {
      <ng-container
        [ngTemplateOutlet]="searchTemplate"
        [ngTemplateOutletContext]="{
          $implicit: filterValue,
          setValue: setFilterValue,
          placeholderValue: searchPlaceholder
        }"
      ></ng-container>
    }
    @if (stateMessage) {
      <span mriDatalistMetadata left>{{ stateMessage }}</span>
    }
    @if (!stateMessage && showStatistics && (showRecordCount || showSelectCount)) {
      <ng-container
        [ngTemplateOutlet]="statisticsTemplate"
        [ngTemplateOutletContext]="{
          recordCountEnabled: showRecordCount,
          selectCountEnabled: showSelectCount,
          showingCount: filteredList.length,
          totalCount: list.length,
          selectionCount: selectedCount,
          recordCountLabel: recordCountLabel
        }"
      ></ng-container>
    }
  </header>
}
<!--Error when no data-->
@if (isEmptyList() && showLoadingError && !loading) {
  <ng-container
    [ngTemplateOutlet]="errorTemplate"
    [ngTemplateOutletContext]="{
      showLoadingError: showLoadingError,
      showLoadingRetry: showLoadingRetry,
      loadingErrorRetryLabel: loadingErrorRetryLabel,
      loadingErrorMessage: loadingErrorMessage
    }"
  ></ng-container>
}
<!--Error when data is present-->
@if (!isEmptyList() && showLoadingError && !loading) {
  <ng-container
    [ngTemplateOutlet]="errorWithDataTemplate"
    [ngTemplateOutletContext]="{
      showLoadingError: showLoadingError,
      showLoadingRetry: showLoadingRetry,
      loadingErrorRetryLabel: loadingErrorRetryLabel,
      loadingErrorMessage: loadingErrorMessage
    }"
  ></ng-container>
}

<!--when data is empty-->
@if (isEmptyList() && !showLoadingError && !loading) {
  <p class="display-message">No items to show</p>
}

<!-- TODO : We need to revisit this and de-dup the ul. -->
@if (rowHeight) {
  <cdk-virtual-scroll-viewport [itemSize]="rowHeight">
    <ul tabindex="0" role="listbox" class="mri-datalist__list">
      <li
        (click)="selectItemInternal(itemsEquivalent(item, selectedItem) ? undefined : item, false)"
        role="option"
        class="mri-datalist__item"
        [ngClass]="getItemClasses(item)"
        aria-selected="false"
        *cdkVirtualFor="let item of filteredList; trackBy: trackByFunc"
        [attr.aria-selected]="itemsEquivalent(item, selectedItem)"
      >
        <!-- todo: `parentList` from `ngTemplateOutletContext` and replace with `ngTemplateOutletInjector` once on angular v14-->
        @if (itemTemplate) {
          <ng-container
            [ngTemplateOutlet]="itemTemplate"
            [ngTemplateOutletContext]="{
              $implicit: item,
              parentList: this,
              isItemSelected: itemsEquivalent(item, selectedItem)
            }"
          ></ng-container>
        }
      </li>
    </ul>
    <!--Loading when data present-->
    <mri-loading [show]="loading && !isEmptyList()"></mri-loading>
  </cdk-virtual-scroll-viewport>
}

@if (!rowHeight) {
  <ul tabindex="0" role="listbox" class="mri-datalist__list">
    @for (item of filteredList; track trackByFunc($index, item)) {
      <li
        (click)="selectItemInternal(itemsEquivalent(item, selectedItem) ? undefined : item, false)"
        role="option"
        class="mri-datalist__item"
        [ngClass]="getItemClasses($any(item))"
        aria-selected="false"
        [attr.aria-selected]="itemsEquivalent(item, selectedItem)"
      >
        <!-- todo: `parentList` from `ngTemplateOutletContext` and replace with `ngTemplateOutletInjector` once on angular v14-->
        @if (itemTemplate) {
          <ng-container
            [ngTemplateOutlet]="itemTemplate"
            [ngTemplateOutletContext]="{
              $implicit: item,
              parentList: this,
              isItemSelected: itemsEquivalent(item, selectedItem)
            }"
          ></ng-container>
        }
      </li>
    }
    <!--Loading when data present-->
    <mri-loading [show]="loading && !isEmptyList()"></mri-loading>
  </ul>
}
<!--Loading when data is not present-->
<mri-loading [show]="loading && isEmptyList()"></mri-loading>

<ng-template #defaultSearchTemplate let-value let-setValue="setValue" let-placeholder="placeholderValue">
  <mri-datalist-search-box
    [value]="value"
    (valueChange)="setValue($event)"
    [placeholder]="placeholder"
    [disabled]="loading"
  ></mri-datalist-search-box>
</ng-template>

<ng-template
  #defaultStatisticsTemplate
  let-recordCountEnabled="recordCountEnabled"
  let-selectCountEnabled="selectCountEnabled"
  let-showingCount="showingCount"
  let-totalCount="totalCount"
  let-selectionCount="selectionCount"
  let-recordCountLabel="recordCountLabel"
>
  @if (recordCountEnabled) {
    <span mriDatalistMetadata left> Showing {{ showingCount }} of {{ totalCount }} {{ recordCountLabel }} </span>
  }
  @if (selectCountEnabled && selectionCount) {
    <span mriDatalistMetadata right>{{ selectionCount }} selected</span>
  }
</ng-template>

<!--Default template when data is present-->
<ng-template
  #defaultErrorWithDataTemplate
  let-showLoadingError="showLoadingError"
  let-loadingErrorMessage="loadingErrorMessage"
  let-showLoadingRetry="showLoadingRetry"
  let-loadingErrorRetryLabel="loadingErrorRetryLabel"
>
  @if (!isEmptyList() && showLoadingError) {
    <mri-loading-error-banner
      [loadingErrorMessage]="loadingErrorMessage"
      [showLoadingRetry]="showLoadingRetry"
      [loadingErrorRetryLabel]="loadingErrorRetryLabel"
      (retry)="onRetryClick()"
    ></mri-loading-error-banner>
  }
</ng-template>

<!--Default template when no data-->
<ng-template
  #defaultErrorTemplate
  let-showLoadingError="showLoadingError"
  let-loadingErrorMessage="loadingErrorMessage"
  let-showLoadingRetry="showLoadingRetry"
  let-loadingErrorRetryLabel="loadingErrorRetryLabel"
>
  @if (isEmptyList() && !loading) {
    <div class="display-message">
      <p><mri-fld-icon class="mri-mt-medium" icon="alert-circle" size="large"></mri-fld-icon></p>
      <p>{{ loadingErrorMessage }}</p>
      <p>
        @if (showLoadingRetry) {
          <button (click)="onRetryClick()" class="mri-button mri-mb-small">
            {{ loadingErrorRetryLabel }}
          </button>
        }
      </p>
    </div>
  }
</ng-template>

@if (showFooter) {
  <ng-container
    [ngTemplateOutlet]="footerTemplate"
    [ngTemplateOutletContext]="{
      addItem: addItemToList,
      removeItem: removeItemFromList,
      actionsBtnTemplate: actionsBtnTemplate,
      canAdd: canAddItem,
      canRemove: canRemoveItem && !!selectedItem,
      selectedItem: selectedItem,
      showAdd: showAddItem,
      showRemove: showRemoveItem,
      showActions: showItemsActions
    }"
  ></ng-container>
}

<ng-template
  #defaultFooterTemplate
  let-actionsBtnTemplate="actionsBtnTemplate"
  let-add="addItem"
  let-remove="removeItem"
  let-canAdd="canAdd"
  let-canRemove="canRemove"
  let-selectedItem="selectedItem"
  let-showActions="showActions"
  let-showAdd="showAdd"
  let-showRemove="showRemove"
>
  <mri-datalist-footer
    [actionsBtnTemplate]="actionsBtnTemplate"
    [canAdd]="canAdd"
    [canRemove]="canRemove"
    [showAdd]="showAdd"
    [showRemove]="showRemove"
    [showActions]="showActions"
    [selectedItem]="selectedItem"
    (addItemToList)="add()"
    (removeItemFromList)="remove($event)"
  ></mri-datalist-footer>
</ng-template>
