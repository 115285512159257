import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'mri-datalist-item-header',
  standalone: true,
  template: `
    <span class="mri-datalist__item-title mri-datalist__item-truncated-text" title="{{ titleTooltip ?? '' }}">{{
      title
    }}</span>
    @if (subtitle) {
      <span class="mri-datalist__item-subtitle mri-datalist__item-truncated-text" title="{{ subtitleTooltip ?? '' }}">{{
        subtitle
      }}</span>
    }
  `,
  styles: [
    `
      .mri-datalist__item-subtitle {
        color: var(--mri-color-text-datalist-item-subtitle, inherit);
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatalistItemHeaderComponent {
  @Input() title: string | undefined;
  @Input() titleTooltip: string | undefined;

  @Input() subtitle: string | undefined;
  @Input() subtitleTooltip: string | undefined;

  @HostBinding('class.mri-datalist__item-header') readonly hostClass = true;
}
