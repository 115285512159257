import { Component, HostBinding, Input } from '@angular/core';
import { ButtonIconComponent } from '@mri-platform/ui-icon';
import { SvgIconName } from '@mri/svg-icons/dist/ts/mri-icons';

const description = 'Add item';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[mriDatalistAddIconBtn]',
  standalone: true,
  template: ` <mri-btn-icon [icon]="icon" [direction]="'none'"></mri-btn-icon>`,
  imports: [ButtonIconComponent],
  styles: []
})
export class DatalistAddIconBtnComponent {
  @HostBinding('class') readonly hostClass = 'mri-icon-button mri-icon-button--x-small';
  @HostBinding('attr.aria-label') readonly ariaRole = description;
  @HostBinding('attr.title') readonly titleRole = description;
  @Input() icon: SvgIconName = 'plus-sign';
}
