import { Directive, HostBinding, Input, Optional } from '@angular/core';
import { timer } from 'rxjs';
import { DatalistComponent } from './datalist.component';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'mri-toolbar', standalone: true })
export class DatalistItemToolbarDirective {
  private _parentList?: DatalistComponent;

  @HostBinding('class.mri-datalist__item-toolbar') get isDatalistIcon() {
    return this._parentList != null;
  }

  /**
   * todo: remove Input decorator and modify DatalistComponent to use `ngTemplateOutletInjector` once on angular v14
   * */
  @Input() set parentList(value: DatalistComponent | undefined) {
    this._parentList = value;

    if (!value || value.tools != null) return;

    value.tools = true;
    // we're modifying a component higher up in the component chain that angular would have already checked for changes
    // therefore need to manually kick off change detection
    timer(0).subscribe(() => value.cd.markForCheck());
  }

  constructor(@Optional() parentList?: DatalistComponent) {
    this.parentList = parentList;
  }
}
