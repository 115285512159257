import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[mriDatalistMetadata]',
  standalone: true
})
export class DatalistMetadataDirective {
  @HostBinding('class.mri-datalist__metadata-1')
  @Input()
  set left(_value: boolean) {
    this.direction = 'left';
  }
  get left() {
    return this.direction === 'left';
  }

  @HostBinding('class.mri-datalist__metadata-2')
  @Input()
  set right(_value: boolean) {
    this.direction = 'right';
  }
  get right() {
    return this.direction === 'right';
  }

  direction: 'left' | 'right' = 'left';
}
